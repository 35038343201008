<template>
  <v-container>
    <v-card max-width="1200" class="mx-auto mb-3">
      <settings
        @close="getTimes(); showSettings = false"
        @update="getTimes()"
      ></settings>
      <v-row>
        <v-col cols="12" class="pb-3">
          <v-data-table
            :items="mappedTimes"
            :headers="headers"
            :options="options"
            @update:options="options = $event; getTimes(columns)"
            :loading="loading"
            :loading-text="$t('times.loading_times')"
            :server-items-length="totalTimes"
          >
            <template v-slot:no-data>
              <div class="my-10">
                <div>
                  {{$t('times.no_times')}}
                </div>
                <v-btn small class="mt-5" color="primary" @click="$router.push('/videos')">
                  {{$t('times.videos')}}
                </v-btn>
              </div>
            </template>
            <template v-slot:no-results>
              <div class="my-10">
                <div>
                  {{$t('times.no_results')}}
                </div>
              </div>
            </template>
            <template v-slot:item.play="{ item }">
              <v-btn
                fab
                small
                text
                @click="playTime(item)"
              >
                <v-icon
                >mdi-play</v-icon>
              </v-btn>
              <v-menu
                bottom
                :offset-y="true"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    small
                    text
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon size="15">mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list dense>
                  <v-list-item 
                    link
                    @click="choosingWholeTime = item; choosing = true"
                  >
                    <v-list-item-icon>
                      <v-icon>
                        mdi-plus
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{$t('time.add_to_folder')}}</v-list-item-title>
                  </v-list-item>
                  <v-spacer></v-spacer>
                  <v-list-item 
                    link
                    @click="showDeleteConfirmation = true; deletingId = item.id"
                  >
                    <v-list-item-icon>
                      <v-icon>
                        mdi-delete
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{$t('delete')}}</v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <share-btn :resource_id="item.id" resource_type="time"></share-btn>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <PlayClip 
      v-if="playing"
      :visible="playing"
      :time_id="playingTimeId"
      @close="playing = false"
    />
    <confirmation-dialog
      :show="showDeleteConfirmation"
      :text="$t('times.sure_to_delete')"
      :subText="$t('times.sure_to_delete_long')"
      :btnText="$t('delete')"
      btnColor="red"
      @decline="showDeleteConfirmation = false"
      @accept="deletingConfirmed()"
    ></confirmation-dialog>
    <choose-folder
      :visible="choosing"
      @closed="choosing = false"
      @chosen="addToFolder($event)"
      :text="$t('analysis.add_to_folder')"
    ></choose-folder>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import PlayClip from '@/components/video/PlayClip'
import time from '@/utils/player/time'
import Settings from '@/components/times/Settings.vue'
import ConfirmationDialog from '../components/ConfirmationDialog.vue'
import ChooseFolder from '../components/folder/ChooseFolder.vue'
import ShareBtn from '@/components/share/ShareBtn.vue'

export default {
  mixins: [time],
  components: {PlayClip, Settings, ConfirmationDialog, ChooseFolder, ShareBtn},
  data: () => ({
    options: {
      page: 1,
      itemsPerPage: 15,
      sortBy: ['total_time'],
      sortDesc: [false]
    },
    playing: false,
    playingTimeId: null,
    loading: false,
    chosenTimenames: [],
    showSettings: false,
    showDeleteConfirmation: false,
    deletingId: null,
    choosing: false,
    choosingWholeTime: null
  }),
  created() {
    this.initTeamnames()
      .then(() => {
        this.$nextTick(() => {
          this.getTimes()
        })
        // this.getTimes(this.columns)
      })
      .catch(e => this.error(e))
  },
  methods: {
    ...mapActions('time', [
      'initTeamnames',
      'getTeamTimes',
      'deleteTime'
    ]),
    ...mapMutations('time', [
      'SET_COLUMNS'
    ]),
    ...mapActions('noti', [
      'error',
      'info',
      'success'
    ]),
    ...mapActions('folder', [
      'addFolder'
    ]),
    deletingConfirmed() {
      this.deleteTime(this.deletingId)
        .then(() => {
          this.info(this.$t('times.time_deleted'))
          this.getTimes()
        })
        .catch(e => this.error(e))
        .finally(() => {
          this.showDeleteConfirmation = false
        })
    },
    getTimes() {
      this.loading = true
      this.getTeamTimes({
        page: this.options.page - 1, 
        itemsPerPage: this.options.itemsPerPage,
        sortBy: this.options.sortBy[0],
        sortDesc: this.options.sortDesc[0],
        columns: this.columns,
        tags: this.filterByTags.map(e => e.id)
      })
        .then()
        .catch(e => this.error(e))
        .finally(() => {
          this.loading = false
        })
    },
    addToFolder(folder) {
      this.addFolder({
        parent: folder.id,
        name: this.choosingWholeTime.title || this.$t('analysis.no_title'),
        type: 'time',
        time_id: this.choosingWholeTime.id
      })
      .then(() => {
        this.success(this.$t('analysis.time_added_to_folder') + '!')
      })
      .catch(e => this.error(e))
      .finally(() => {
        this.choosing = false
        this.choosingWholeTime = null
      })
    },
    playTime(time) {
      this.playingTimeId = time.id
      this.playing = true
    }
  },
  computed: {
    ...mapGetters('time', [
      'timenameOrdered',
      'totalTimes',
      'times',
      'columns',
      'allTimenameNamesOnly',
      'filterByTags'
    ]),
    headers() {
      return [
        {text: 'Video', value: 'video_name'},
        {'text': 'Total', value: 'total_time'},
        {'text': 'Title', value: 'title', sortable: false},
      ].concat(this.showingTimenamesForHeader).concat({
        text: '',
        value: 'play',
        sortable: false
      })
    },
    showingTimenamesForHeader() {
      return this.timenameOrderedAndMapped.filter(t => {
          return this.columns.includes(t.text)
        })
    },
    timenameOrderedAndMapped() {
      return this.timenameOrdered.map(t => {
        return {
          value: t.name,
          text: t.name,
          sortable: false
        }
      })
    },
    mappedTimes() {
      return this.times.map(time => {
        let data = JSON.parse(time.data)
        let mod = {
          ...time,
          total_time: this.secondsToPrettyTimer(time.total_time)
        }
        data.forEach(d => {
          mod[d.name] = this.secondsToPrettyTimer(d.time)
        })
        return mod
      })
    }
  }
}
</script>