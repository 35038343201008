<template>
  <!-- <v-dialog 
    @click:outside="$emit('close')" 
    max-width="600" 
    v-model="visible"
  > -->
  <div>
    <div style="text-align: center;" class="py-3">
      <v-btn rounded small @click="visible = !visible">
        {{$t('settings.settings')}}
        <v-icon v-if="visible">mdi-chevron-up</v-icon>
        <v-icon v-else>mdi-chevron-down</v-icon>
      </v-btn>
    </div>
    <v-expand-transition>
      <div v-show="visible">
        <v-card flat>
          <v-card-text>
            <v-card-title>
              {{$t('time.visible_columns')}}
            </v-card-title>
            <v-card-text>
              {{$t('time.visible_columns_long')}}
            </v-card-text>            
            <v-select
              max-width="500"
              :value="showingTimenamesForHeader"
              @input="changedColumns($event)"
              solo
              dense
              chips
              multiple
              :persistent-hint="true"
              :items="allTimenameNamesOnly"
            ></v-select>
            <v-card-title>
              {{$t('time.filter_by_tags')}}
            </v-card-title>
            <v-card-text>
              {{$t('time.filter_by_tags_long')}}
            </v-card-text>
            <v-btn
              rounded
              large
              @click="showTags = true"
            >
              {{$t('time.choose_tags')}}
              <v-chip class="ml-3">
                {{ filterByTags.length ? filterByTags.length : $t('time.all') }}
              </v-chip>
            </v-btn>
            <div class="mt-6">
              <v-chip
                v-for="(tag, i) in filterByTags"
                :key="'tagfilterby' + i"
                color="primary"
                class="mt-1"
              >
                {{tag.tag_name}}
              </v-chip>
            </div>
          </v-card-text>
        </v-card>
        <tag-adder-dialog
          :show="showTags"
          @close="showTags = false; $emit('update')"
          @input="SET_FILTER_TAG($event);"
          :value="filterByTags"
        ></tag-adder-dialog>
      </div>
    </v-expand-transition>
  </div>
  <!-- </v-dialog> -->
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'
import TagAdderDialog from '@/components/tag/TagAdderDialog.vue'
export default {
  components: { TagAdderDialog },
  created() {
    this.initTags()
  },
  data: () => ({
    showTags: false,
    chosen: [],
    visible: false
  }),
  computed: {
    ...mapGetters('time', [
      'columns',
      'timenameOrdered',
      'allTimenameNamesOnly',
      'filterByTags'
    ]),
    ...mapGetters('tag', [
      'tag_groups'
    ]),
    showingTimenamesForHeader() {
      return this.timenameOrderedAndMapped.filter(t => {
          return this.columns.includes(t.text)
        })
    },
    timenameOrderedAndMapped() {
      return this.timenameOrdered.map(t => {
        return {
          value: t.name,
          text: t.name,
          sortable: false
        }
      })
    },
  },
  methods: {
    ...mapMutations('time', [
      'SET_COLUMNS',
      'SET_FILTER_TAG'
    ]),
    ...mapActions('time', [
      'getTimes'
    ]),
    ...mapActions('tag', [
      'initTags'
    ]),
    changedColumns(e) {
      this.SET_COLUMNS(e)
      this.$emit('update')
    },
  }
}
</script>